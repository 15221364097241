<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-user" /> <strong>Profile</strong>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol lg="4" class="text-center">
              <div>
                <img
                  src="img/user_profile/user.png"
                  class="rounded-circle"
                  style="width: 200px; height: 200px; object-fit: cover"
                />
              </div>
              <div>
                <p class="my-2">
                  <span class="badge badge-info">
                    {{ user.role_id }}
                  </span>
                </p>
                <p class="my-2 text-success">
                 <CIcon name="Profile"/> <span class="d-inline-block align-middle"> {{ user.username }} </span>
                </p>
                <h4 class="my-3">
                  {{
                    $store.state.current_user.first_name +
                    " " +
                    $store.state.current_user.last_name
                  }}
                </h4>
                <p class="my-2">{{ $store.state.current_user.email }}</p>
              </div>
            </CCol>
            <CCol lg="8">
              <CTabs>
                <CTab title="Profile" active>
                  <CCard>
                    <CCardBody>
                      <CRow>
                        <CCol sm="6">
                          <CInput
                            type="text"
                            autocomplete="off"
                            label="First Name"
                            v-model="user.first_name"
                            placeholder="Enter first name..."
                          />
                        </CCol>
                        <CCol sm="6">
                          <CInput
                            type="text"
                            autocomplete="off"
                            label="Last Name"
                            v-model="user.last_name"
                            placeholder="Enter last name..."
                          />
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol sm="6">
                          <CInput
                            type="email"
                            autocomplete="off"
                            label="Email"
                            v-model="user.email"
                            placeholder="Enter email.."
                            @keyup="check_email"
                            :addInputClasses="
                              email_invalid ? 'is-invalid' : null
                            "
                          />
                        </CCol>
                        <CCol sm="6">
                          <CInput
                            type="number"
                            autocomplete="off"
                            label="Contact No."
                            v-model="user.contact_no"
                            @keyup="check_contact_no"
                            :addInputClasses="
                              contact_no_invalid ? 'is-invalid' : null
                            "
                            :isValid="user.contact_no ? true : null"
                          />
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol sm="12">
                          <CInput
                            type="number"
                            autocomplete="off"
                            label="NID No."
                            v-model="user.nid_number"
                          />
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol sm="12">
                          <CTextarea
                            label="Address"
                            v-model="user.address"
                            placeholder="Enter Address..."
                          />
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CTab>
                <CTab title="Bank Information">
                  <CCard>
                    <CCardBody>
                      <CRow>
                        <CCol sm="12">
                          <CInput
                            type="text"
                            autocomplete="off"
                            label="Bank Account"
                            v-model="user.bank_account"
                          />
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CTab>
              </CTabs>
              <div class="text-right">
                <CButton
                  size="sm"
                  color="success"
                  @click="submit"
                  v-if="!isSaving"
                  ><CIcon name="cil-check-circle" /> Update Profile</CButton
                >
                <CButton color="secondary" class="px-4" disabled v-else>
                  <CSpinner grow size="sm" /> Saving..
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Profile",
  created() {
    this.beforeEditingCache = JSON.stringify(this.$store.state.current_user);
    this.user = this.$store.state.current_user
      ? JSON.parse(this.beforeEditingCache)
      : [];
  },
  data() {
    return {
      email_invalid: false,
      last_name_invalid: false,
      contact_no_invalid: false,
      first_name_invalid: false,
      user: null,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.roles.isLoading,
      isSaving: (state) => state.users.isSaving,
    }),
  },
  methods: {
    checkForm() {
      if (
        !this.user.email ||
        !this.user.last_name ||
        !this.user.first_name ||
        !this.user.contact_no
      ) {
        this.check_last_name();
        this.check_first_name();
        this.check_contact_no();
        this.check_email();
      } else {
        return true;
      }
    },
    check_last_name() {
      if (!this.user.last_name) {
        this.last_name_invalid = true;
      } else {
        this.last_name_invalid = false;
      }
    },
    check_first_name() {
      if (!this.user.first_name) {
        this.first_name_invalid = true;
      } else {
        this.first_name_invalid = false;
      }
    },
    check_email() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(this.user.email).toLowerCase())) {
        this.email_invalid = false;
      } else {
        this.email_invalid = true;
      }
    },
    check_password() {
      if (!this.user.password) {
        this.password_invalid = true;
      } else {
        this.password_invalid = false;
      }
    },
    check_password_confirm() {
      if (!this.user.password_confirm) {
        this.password_confirm_invalid = true;
      } else if (this.password !== this.password_confirm) {
        this.password_confirm_invalid = true;
      } else {
        this.password_confirm_invalid = false;
      }
    },

    check_contact_no() {
      if (!this.user.contact_no) {
        this.contact_no_invalid = true;
      } else {
        this.contact_no_invalid = false;
      }
    },
    submit() {
      if (this.checkForm()) {
        this.$store
          .dispatch("users/updateProfile", {
            Authorization: "bearer " + this.$store.state.access_token,
            email: this.user.email,
            username: this.user.username,
            address: this.user.address,
            contact_no: 0 + this.user.contact_no,
            nid_number: this.user.nid_number,
            bank_account: this.user.bank_account,
            last_name: this.user.last_name,
            first_name: this.user.first_name,
            password: this.user.password,
            password_confirm: this.user.password_confirm,
          })
          .then((response) => {});
      } else {
        this.checkForm();
      }
    },
  },
};
</script>
